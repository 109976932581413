<template>
  <div>
    <Banner bg-image="yellow-bg.jpg" talk-img="ask-for-help.png">
      <template v-slot:title> Learn how to ask for help</template>
      <template v-slot:subtitle>
        When you are feeling down, it is understandable that you may want to
        shut yourself off from the rest of the world - withdraw from friends and
        family. It takes courage to open up about hurt feelings. However,
        talking with someone about what you are going through can be very
        helpful.
      </template>
      <template v-slot:description> </template>
    </Banner>
    <!-- Strategies section  -->
    <section class="pt-10 gradient-white md:pt-14">
      <div class="max-w-6xl px-4 mx-auto sm:px-6">
        <div
          class="mb-6 text-2xl font-bold text-center call-text md:text-3xl text-blue"
        >
          Strategies to help you open up to someone about suicide
        </div>

        <div class="grid grid-cols-1 gap-6 p-8 lg:grid-cols-2">
          <!-- Block 1 -->
          <!-- Add the slider oky su rish factors -->
          <div>
            <div class="mb-3 faq-tab">
              <input type="checkbox" id="rd1" name="rd" />
              <label class="tab-label" for="rd1"
                >Choose someone you trust</label
              >
              <div class="faq-tab-content">
                <p>
                  Think of a person – friend, family member, elder, or
                  professional – in your life that you feel comfortable opening
                  up to. You can also refer to your contact list from your
                  safety plan. Even though you may feel alone, someone is always
                  available to listen.
                </p>
              </div>
            </div>
          </div>
          <!-- Block 2  -->
          <div>
            <div class="mb-3 faq-tab">
              <input type="checkbox" id="rd2" name="rd" />
              <label class="tab-label" for="rd2"
                >Write what you want to say</label
              >
              <div class="faq-tab-content">
                <p>
                  You may not be sure how to start, or feel shy. It’s okay if
                  you don’t know how to start the conversation. Try thinking
                  through some ideas in advance by writing a letter or
                  journaling, which you can later share with someone you trust.
                  Writing down your thoughts can help you communicate more
                  clearly in the moment if you’re nervous or stressed.
                </p>
              </div>
            </div>
          </div>
          <!-- Block 3 -->
          <div>
            <div class="mb-3 faq-tab">
              <input type="checkbox" id="rd3" name="rd" />
              <label class="tab-label" for="rd3"> Be honest</label>
              <div class="faq-tab-content">
                <p>
                  Being open during the conversation can help the person
                  listening understand what you are going through and know how
                  to help. Tell them if you have thoughts of suicide, if you’ve
                  taken any actions so far or if you’re planning to take action
                  in the near future.
                </p>
              </div>
            </div>
          </div>
          <!-- Block 4 -->
          <div>
            <div class="mb-3 faq-tab">
              <input type="checkbox" id="rd4" name="rd" />
              <label class="tab-label" for="rd4">Be kind to yourself</label>
              <div class="faq-tab-content">
                <p>
                  One of the best ways to show yourself kindness is to take good
                  care of yourself. Get good sleep, every day take some time for
                  yourself and do something that brings you joy. You can draw,
                  journal, write short stories, engage in cultural activities,
                  play a musical instrument, or do anything else that you love
                  to do. It is also important to pay attention to your inner
                  critic. Try to reduce your negative self-talk.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <!-- Start Conversation section  -->

    <section class="pt-10 my-8 gradient-white md:pt-14 md:my-14">
      <div class="mx-auto">
        <div
          class="mb-6 text-2xl font-bold text-center call-text md:text-3xl text-blue"
        >
          How to start a conversation
        </div>
        <p class="w-full mx-auto text-center md:w-2/4">
          If you don’t know where to start, here are some examples of how you
          could start a conversation.
        </p>

        <div id="conversation-slider" class="px-4 mx-auto splide sm:px-6">
          <div class="mx-8 my-8">
            <Splide :options="options">
              <SplideSlide>
                <div class="flex justify-center qoute-sect">
                  <div class="qoute-text">
                    <p>
                      ”I really need help. You know how we’ve talked about all
                      the bad things going on in my life? There’s one part I
                      haven’t told you. It’s so hard right now that sometimes I
                      think the only answer is suicide.“
                    </p>
                  </div>
                </div>
              </SplideSlide>
              <SplideSlide>
                <div class="flex justify-center qoute-sect">
                  <div class="qoute-text">
                    <p>
                      “Talking to someone helped me, I was feeling hopeless,
                      alone and abandoned. I was experiencing suicidal thoughts
                      often, but then I talked to someone, and I got the support
                      I needed”
                    </p>
                  </div>
                </div>
              </SplideSlide>
              <SplideSlide>
                <div class="flex justify-center qoute-sect">
                  <div class="qoute-text">
                    <p>
                      Some really hard things are going on right now. I’m so sad
                      I don’t know what to do. It’s so bad I’m think kill myself
                      may be my only way out.”
                    </p>
                  </div>
                </div>
              </SplideSlide>
              <SplideSlide>
                <div class="flex justify-center qoute-sect">
                  <div class="qoute-text">
                    <p>
                      "I need to tell you something really important. My life is
                      really hard right now, and I’m not doing so well. There’s
                      lots of things going on and I’m not sure I can handle it
                      anymore. Lately I’ve been thinking about suicide—like life
                      is not worth living or you would be better off without
                      me."
                    </p>
                  </div>
                </div>
              </SplideSlide>

              <template #after-track>
                <div class="splide__arrows">
                  <button class="splide__arrow splide__arrow--prev">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      class="overflow-visible"
                    >
                      <path
                        id="Path_7423"
                        data-name="Path 7423"
                        d="M831.1,528.738l15.155-15.153a13.124,13.124,0,0,0,0-18.562l-15-14.995a46.056,46.056,0,0,1-.159,48.709Z"
                        transform="translate(-831.098 -480.029)"
                        fill="#3c5eaa"
                      />
                    </svg>
                  </button>
                  <button class="splide__arrow splide__arrow--next">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      class="overflow-visible"
                    >
                      <path
                        id="Path_7423"
                        data-name="Path 7423"
                        d="M831.1,528.738l15.155-15.153a13.124,13.124,0,0,0,0-18.562l-15-14.995a46.056,46.056,0,0,1-.159,48.709Z"
                        transform="translate(-831.098 -480.029)"
                        fill="#3c5eaa"
                      />
                    </svg>
                  </button>
                </div>
              </template>
            </Splide>
          </div>
        </div>
      </div>
    </section>

    <!-- Tips Section  -->
    <section class="pt-10 gradient-white md:pt-14">
      <div class="max-w-6xl px-4 mx-auto sm:px-6">
        <div
          class="mb-6 text-2xl font-bold text-center call-text md:text-3xl text-blue"
        >
          Tips for asking for help
        </div>

        <div class="grid grid-cols-1 gap-6 lg:grid-cols-3">
          <!-- The outer div is important for grid  -->
          <div>
            <div class="mb-3 faq-tab">
              <input type="checkbox" id="rd5" name="rd" />
              <label class="tab-label" for="rd5"
                >Be clear about your feelings</label
              >
              <div class="faq-tab-content">
                <p>Try to clearly express how much you are struggling.</p>
              </div>
            </div>
          </div>
          <div>
            <div class="mb-3 faq-tab">
              <input type="checkbox" id="rd6" name="rd" />
              <label class="tab-label" for="rd6"
                >Talk about how your life is being affected</label
              >
              <div class="faq-tab-content">
                <p>
                  Start by talking about the difficulties you are currently
                  facing and explain the effects your struggles are having on
                  your life.
                </p>
              </div>
            </div>
          </div>
          <div>
            <div class="mb-3 faq-tab">
              <input type="checkbox" id="rd7" name="rd" />
              <label class="tab-label" for="rd7"
                >Say you are thinking of suicide</label
              >
              <div class="faq-tab-content">
                <p>
                  Most importantly, clearly say that you are thinking of
                  suicide. This will let the other person know that you are in
                  crisis, and need help right away.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <!-- Quick Access tools  -->
    <section class="my-8 bg-white md:my-14 md:pb-14">
      <div class="max-w-6xl px-4 mx-auto text-center sm:px-6">
        <div class="mb-6 text-2xl font-bold call-text md:text-3xl text-blue">
          If you don’t know who to talk to
        </div>
        <p class="w-full mx-auto md:w-3/4">
          If you find talking to a loved one too difficult, call a crisis
          hotline or speak to a professional counselor where you can get support
          anonymously.
        </p>

        <div class="grid grid-cols-1 mt-10 md:grid-cols-2 gap-y-8 gap-x-6">
          <router-link
            @click="
              this.$gtm.trackEvent({
                event: 'gtm.btnClick',
                category: 'button',
                btnTitle: 'CallACrisisHotline-btn',
                label: 'Call a crisis hotline',
                action: 'Clicked on',
              })
            "
            :to="{ name: 'CallACrisisHotline' }"
          >
            <QuickAccess
              arrow="text-green"
              text="Call a crisis hotline"
              img="call-a-crisis-hotline.svg"
            />
          </router-link>
          <router-link
            @click="
              this.$gtm.trackEvent({
                event: 'gtm.btnClick',
                category: 'button',
                btnTitle: 'FindACounselor-btn',
                label: 'Find a counselor',
                action: 'Clicked on',
              })
            "
            :to="{ name: 'FindACounselor' }"
          >
            <QuickAccess
              arrow="text-yellow"
              text="Find a counselor"
              img="find-a-counselor.svg"
            />
          </router-link>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import Banner from "@/components/Banner.vue";
import QuickAccess from "@/components/QuickAccess.vue";
import { Splide, SplideSlide } from "@splidejs/vue-splide";

export default {
  name: "LearnHowToAskForHelp",
  setup() {
    const options = {
      type: "loop",
      gap: "120px",
      autoplay: false,
      perPage: 1,
      perMove: 1,
      pagination: false,
      arrows: true,
      trimSpace: false,
      padding: {
        right: "250px",
        left: "250px",
      },
      breakpoints: {
        975: {
          perPage: 1,
          gap: "40px",
          padding: {
            right: "5px",
            left: "5px",
          },
        },
        375: {
          perPage: 1,
          gap: "0px",
          padding: {
            right: "0px",
            left: "0px",
          },
        },
      },
    };

    return { options };
  },
  components: {
    Banner,
    QuickAccess,
    Splide,
    SplideSlide,
  },
  methods: {
    revealBtn() {
      // Add logic to expand Tips boxes when clicked
    },
  },
};
</script>

<style scoped>
.last-sect,
.gradient-white {
  background: linear-gradient(to bottom, #f8f8f8, #fff);
}

.tips-block {
  background: #f8f8f8;
  border: 5px solid #3c5eaa;
  border-radius: 10px;
}

/* splide */
.splide__arrow {
  padding: 0;
  opacity: 100;
  background: none;
}

.splide__arrow:hover {
  opacity: 0.8;
}
@media screen and (min-width: 1150px) {
  .splide__arrow svg {
    width: 40em;
  }
}

/* QUOTE */
.qoute-text {
  background: #f0f2f8;
  padding: 40px 100px;
  font-style: italic;
  position: relative;
  z-index: 1;
}
.qoute-text:after,
.qoute-text:before {
  content: "";
  position: absolute;
  top: 38px;
  left: 15px;
  right: 0;
  bottom: 0;
  background-image: url(../../assets/images/quote.png);
  background-repeat: no-repeat;
  background-size: 4rem;
  z-index: -1;
  display: block;
}
.qoute-text:before {
  top: 0;
  bottom: 15px;
  right: 15px;
  transform: rotate(180deg);
}
/* ============================= */
.faq-tab {
  width: 100%;
  color: white;
  overflow: hidden;
}
.tab-label {
  display: flex;
  justify-content: space-between;
  padding: 1em;
  background: rgba(60, 94, 170, 0.06);
  color: #3c5eaa;
  cursor: pointer;
  font-size: 20px;
}
.tab-label::after {
  content: "";
  background-image: url(../../assets/images/arrow.svg);
  width: 1em;
  text-align: center;
  transition: all 0.35s;
  background-repeat: no-repeat;
  background-position: center;
}
.faq-tab-content {
  max-height: 0;
  padding: 0 1em;
  color: #2c3e50;
  background: white;
  transition: all 0.35s;
}
.faq-tab input {
  position: absolute;
  opacity: 0;
  z-index: -1;
}
input:checked + .tab-label {
  background: #3c5eaa;
  color: #fff;
}
input:checked + .tab-label::after {
  background-image: url(../../assets/images/arrow-up-white.svg);
}
input:checked ~ .faq-tab-content {
  max-height: 100vh;
  padding: 1em;
}
.faq-tab {
  border: 5px solid #3c5eaa;
  border-radius: 10px;
}
.tab-label {
  background: #f8f8f8;
  font-weight: bold;
  height: 135px;
}
input:checked + .tab-label {
  background: #f8f8f8;
  color: #3c5eaa;
  height: auto;
}
.tab-label::after {
  background-position: -2px 10px;
  background-size: 1.8rem;
  width: 2.5rem;
}
input:checked + .tab-label::after {
  background-image: url(../../assets/images/arrow-up.svg);
}
.faq-tab-content {
  background: #f8f8f8;
  line-height: 30px;
}
</style>
